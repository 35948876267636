
const config = { 
    REACT_APP_BASE_URL:"__BASE_URL__",
    REACT_APP_API_BASE_URL:"https://api.gomaxpain.com",
    REACT_APP_CRM_SSO_CONFIG:"9a222289-b641-41f9-8d13-9c585b645f90",
    REACT_APP_STRIPE_KEY:"pk_live_51Q5BKaGELLcBCcZ9yInYjL3NR1DVKAJxVScAhiXBvxm00FhFcKT2gRiWwPvBEHKxkhedUFbGv48GbTlGCWhfb8HQ00gnRH3bvo",
    REACT_APP_STAXX_KEY:"__STAXX_KEY__",
    REACT_APP_SQUARE_APP_KEY:"__SQUARE_APP_KEY__",
    REACT_APP_SQUARE_API_KEY:"__SQUARE_API_KEY__",
    REACT_APP_SQUARE_LOCATION_KEY:"__SQUARE_LOCATION_KEY__",
    REACT_APP_DEPLOY_ENV:"__ENV__",
    REACT_APP_SALESFORCE_URL:"__SALES_FORCE_URL__",
    //REACT_APP_GOOGLE_API_KEY:"AIzaSyCjn4U7o_J0AHbNBvkyijucaX_KgTU-46w",
    REACT_APP_GOOGLE_API_KEY:"AIzaSyBuBh6xDAM2RdH-nAon9NDBi_oqgRGvFg8",
    REACT_APP_SITE_CUSTOMER_URL:"https://patient.poundpain.com",
    REACT_APP_SITE_PROVIDER_URL:"https://provider.poundpain.com",
    REACT_APP_SITE_LEGAL_URL:"https://legal.poundpain.com",
    REACT_APP_BASE_SITE_TYPE:"provider"
}

export default config;
